import { EventSortField, SortDirection } from "./enums";
import { i18n } from "@/utils/i18n";

export const SORTING_UPCOMING = {
  title: i18n.t("Upcoming Events") as string,
  orderBy: EventSortField.BEGINS_ON,
  direction: SortDirection.ASC,
};

export const SORTING_CREATED = {
  title: i18n.t("Recently created Events") as string,
  orderBy: EventSortField.INSERTED_AT,
  direction: SortDirection.DESC,
};
